import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { ResourceBrowser } from '../components/ResourceBrowser';
import TextHero from '../components/TextHero';
import SiteMeta from '../components/SiteMeta';

const ThankYouPage = ({ data }) => {
  const {
    allContentfulResource,
    contentfulAncillaryPage: {
      metaTitle,
      metaDescription: { metaDescription },
      heroTitle,
      heroBody: { heroBody }
    },
  } = data;

  const TextHeroContent = {
    title: heroTitle,
    subtitle: heroBody
  }

  const resources = allContentfulResource.edges.map((n) => n.node);
  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <TextHero {...TextHeroContent} />
      <ResourceBrowser resources={resources} />
    </Layout>
  );
};

export default ThankYouPage;

export const query = graphql`
  query {
    contentfulResourceLibrary(slug: {eq: "wfe-resources"}) {
      metaTitle
      metaDescription {
        metaDescription
      }
    }
    contentfulAncillaryPage(slug: {eq: "wfe-contact-demo-ty"}) {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroBody {
        heroBody
      }
      heroTitle
    }
    allContentfulResource(filter: {microsite: {eq: "wfe"}}) {
      edges {
        node {
          ...ResourceCard
        }
      }
    }
  }
`;
